import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import { Col, Row, Space } from 'antd';
import { BaseFormInputItem } from '../../forms/components/BaseFormInputItem/BaseFormInputItem';
import { Option, Select } from '../../selects/Select/Select';
import { Radio, RadioGroup } from '../../Radio/Radio';
import { Input } from '../../inputs/Input/Input';
import ShouldRenderInput from '../ShouldRenderInput';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { useEffect, useState } from 'react';
import { valoresDefaultPorId } from '../PerfilEnvio/PerfilEnvioUtils';

interface IdentificadorMotoristaProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  disableAllFields?: boolean;
  setPropertyValues?: (data: PropertyValue[]) => void;
  firmwareGroup?: string;
}

const IdentificadorMotorista: React.FC<IdentificadorMotoristaProps> = ({
  properties,
  setProperties,
  moduleData,
  disableAllFields,
  setPropertyValues,
  firmwareGroup,
}) => {
  const [idMapping, setIdMapping] = useState<{ [key: number]: number }>({});

  useEffect(() => {
    const newIdMapping: { [key: number]: number } = {};
    moduleData.forEach((module) => {
      if (module.idEmbarcado && module.idPropriedade) {
        newIdMapping[module.idEmbarcado] = module.idPropriedade;
      }
    });
    setIdMapping(newIdMapping);
  }, [moduleData]);

  useEffect(() => {
    if (Object.keys(idMapping).length > 0 && properties.length === 0) {
      const newProperties = Object.keys(idMapping).map((idEmbarcado) => {
        const idStr = idEmbarcado.toString(); // Converte o ID para string
        const valorPadrao = idStr in valoresDefaultPorId ? valoresDefaultPorId[idStr] : '';

        return {
          idPropriedade: idMapping[Number(idEmbarcado)],
          valorPropriedade: valorPadrao,
          idEmbarcado: Number(idEmbarcado),
        };
      });
      setProperties((prevState) => [...prevState, ...newProperties]);
    }
  }, [idMapping, setProperties, properties.length]);

  const handleInputChange = (idEmbarcado: number, value: string) => {
    const idPropriedade = idMapping[idEmbarcado];
    if (idPropriedade !== undefined) {
      setProperties((prevValue) => {
        const newProperties = [...prevValue];
        const index = newProperties.findIndex((property) => property.idPropriedade === idPropriedade);
        if (index !== -1) newProperties[index].valorPropriedade = value;
        if (setPropertyValues) {
          setPropertyValues(newProperties);
        }
        return newProperties;
      });
    }
  };

  const propertyValue = (idEmbarcado: number) => {
    const idPropriedade = idMapping[idEmbarcado];
    const resposta = properties.find((property) => property.idPropriedade === idPropriedade)?.valorPropriedade;

    return resposta || '';
  };

  const handleDisableBlockAndLogoutPackage = () => {
    handleInputChange(DevicePropertyType.TipoBloqueio, '1');
    handleInputChange(DevicePropertyType.PacoteLogout, '0');
    handleInputChange(DevicePropertyType.AcaoDesligarVeiculo, '');
    handleInputChange(DevicePropertyType.AcaoBloqueioTemporizado, '0');
  };

  const handleDisableActionOnTurnOffVehicle = () => {
    handleInputChange(DevicePropertyType.AcaoDesligarVeiculo, '');
  };

  const handleDisableTempBlock = () => {
    handleInputChange(DevicePropertyType.AcaoBloqueioTemporizado, '0');
    handleInputChange(DevicePropertyType.PacoteLogout, '0');
    handleDisableActionOnTurnOffVehicle();
  };

  return (
    <>
      <Row gutter={18} style={{ marginTop: '1rem' }}>
        <ShouldRenderInput
          propertyGroupedData={moduleData}
          idEmbarcado={DevicePropertyType.ProtocoloComunicacaoRastreador}
        >
          <Col xl={8} md={12} sm={24}>
            <BaseFormInputItem label="Protocolo de comunicação" supportText="Escolha apenas uma opção">
              <Select
                disabled={disableAllFields}
                placeholder="Selecione o protocolo de comunicação"
                value={propertyValue(DevicePropertyType.ProtocoloComunicacaoRastreador)}
                onChange={(value) =>
                  handleInputChange(DevicePropertyType.ProtocoloComunicacaoRastreador, value as string)
                }
                showArrow
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option className="option-tab" value="0">
                  Jmak
                </Option>
                <Option className="option-tab" value="1">
                  Suntech
                </Option>
                <Option className="option-tab" value="2">
                  Queclink
                </Option>
                <Option className="option-tab" value="3">
                  Somente tag
                </Option>
                <Option className="option-tab" value="4">
                  Galileo (4 bytes)
                </Option>
                {(firmwareGroup === '1.2.x.x' || firmwareGroup === '1.3.x.x') && (
                  <>
                    <Option className="option-tab" value="5">
                      Galileo One Wire
                    </Option>

                    <Option className="option-tab" value="5">
                      Suntech One Wire
                    </Option>
                  </>
                )}

                {firmwareGroup === '1.3.x.x' && (
                  <>
                    <Option className="option-tab" value="6">
                      Teltonika One Wire
                    </Option>
                  </>
                )}
              </Select>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.TipoBloqueio}>
          <Col xl={5} md={12} sm={24}>
            <BaseFormInputItem label="Bloqueio">
              <RadioGroup
                disabled={disableAllFields || propertyValue(DevicePropertyType.TipoIdentificacao) === '1'}
                value={propertyValue(DevicePropertyType.TipoBloqueio)}
                onChange={(e) => {
                  handleInputChange(DevicePropertyType.TipoBloqueio, e.target.value);
                  if (e.target.value == 1) {
                    handleDisableTempBlock();
                  }
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="0">Qualquer cartão RFID</Radio>
                  <Radio value="2">Somente cartões cadastrados</Radio>
                  <Radio value="1">Desativado</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.TipoIdentificacao}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Tipo de identificação">
              <RadioGroup
                disabled={disableAllFields}
                value={propertyValue(DevicePropertyType.TipoIdentificacao)}
                onChange={(e) => {
                  handleInputChange(DevicePropertyType.TipoIdentificacao, e.target.value);
                  if (e.target.value == 1) {
                    handleDisableBlockAndLogoutPackage();
                  }
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="0">Motorista</Radio>
                  <Radio value="1">Passageiro</Radio>
                  <Radio value="2">Motorista e passageiro</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.PacoteLogout}>
          <Col xl={3} md={12} sm={24}>
            <BaseFormInputItem label="Pacote de logout">
              <RadioGroup
                disabled={disableAllFields || propertyValue(DevicePropertyType.TipoBloqueio) === '1'}
                value={propertyValue(DevicePropertyType.PacoteLogout)}
                onChange={(e) => {
                  handleInputChange(DevicePropertyType.PacoteLogout, e.target.value);
                  if (e.target.value == 0) {
                    handleDisableActionOnTurnOffVehicle();
                  }

                  if (e.target.value == 1) {
                    handleInputChange(DevicePropertyType.AcaoDesligarVeiculo, '0');
                  }
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="1">Ativo</Radio>
                  <Radio value="0">Inativo</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.AcaoDesligarVeiculo}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Ação ao desligar o veículo">
              <RadioGroup
                disabled={disableAllFields || propertyValue(DevicePropertyType.PacoteLogout) === '0'}
                value={propertyValue(DevicePropertyType.AcaoDesligarVeiculo)}
                onChange={(e) => {
                  handleInputChange(DevicePropertyType.AcaoDesligarVeiculo, e.target.value);
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="0">Logout automático</Radio>
                  <Radio value="1">Logout por RFID</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
      <Row gutter={18}>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.DelayIgnicaoLigada}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Delay de ignição ligada (s)" supportText="Entre 1 a 30 segundos">
              <Input
                disabled={disableAllFields}
                type="text"
                max={30}
                min={1}
                className="ant-input"
                placeholder="Digite o intervalo"
                value={propertyValue(DevicePropertyType.DelayIgnicaoLigada)}
                onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                  const char = e.data;
                  if (char && !char.match(/[0-9]/)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (Number(e.target.value) > 0 && Number(e.target.value) <= 30)
                    handleInputChange(DevicePropertyType.DelayIgnicaoLigada, e.target.value);
                }}
                onBlur={(e) => {
                  if (e.currentTarget.value.length <= 0) {
                    handleInputChange(DevicePropertyType.DelayIgnicaoLigada, '10');
                  }
                }}
              />
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.DelayIgnicaoDesligada}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Delay de ignição desligada (s)" supportText="Entre 1 a 30 segundos">
              <Input
                disabled={disableAllFields}
                type="text"
                max={30}
                min={1}
                onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>) => {
                  const char = e.data;
                  if (char && !char.match(/[0-9]/)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (Number(e.target.value) > 0 && Number(e.target.value) <= 30)
                    handleInputChange(DevicePropertyType.DelayIgnicaoDesligada, e.target.value);
                }}
                onBlur={(e) => {
                  if (e.currentTarget.value.length <= 0) {
                    handleInputChange(DevicePropertyType.DelayIgnicaoDesligada, '10');
                  }
                }}
                className="ant-input"
                placeholder="Digite o intervalo"
                value={propertyValue(DevicePropertyType.DelayIgnicaoDesligada)}
              />
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.AcaoBloqueioTemporizado}>
          <Col xl={5} md={12} sm={24}>
            <BaseFormInputItem label="Bloqueio temporizado (15s)">
              <RadioGroup
                disabled={disableAllFields || propertyValue(DevicePropertyType.TipoBloqueio) === '1'}
                value={
                  propertyValue(DevicePropertyType.TipoBloqueio) !== '1'
                    ? propertyValue(DevicePropertyType.AcaoBloqueioTemporizado)
                    : '0'
                }
                onChange={(e) => {
                  handleInputChange(DevicePropertyType.AcaoBloqueioTemporizado, e.target.value);
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="1">Ativo</Radio>
                  <Radio value="0">Inativo</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.EstadoBuzzer}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Buzzer">
              <RadioGroup
                disabled={disableAllFields}
                value={propertyValue(DevicePropertyType.EstadoBuzzer)}
                onChange={(e) => {
                  handleInputChange(DevicePropertyType.EstadoBuzzer, e.target.value);
                }}
              >
                <Space size={0} direction="vertical">
                  <Radio value="1">Ativo</Radio>
                  <Radio value="0">Inativo</Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
      <Row gutter={18}>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.VelocidadeTTL}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Velocidade serial - TTL (kbps)" supportText="Escolha apenas uma opção">
              <Select
                disabled={disableAllFields}
                placeholder="Selecione a velocidade"
                value={propertyValue(DevicePropertyType.VelocidadeTTL) || undefined}
                onChange={(value) => {
                  handleInputChange(DevicePropertyType.VelocidadeTTL, value as string);
                }}
                showArrow
                showSearch
                filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
              >
                <Option className="option-tab" value="115200">
                  115.200
                </Option>
                <Option className="option-tab" value="19200">
                  19.200
                </Option>
                <Option className="option-tab" value="9600">
                  9.600
                </Option>
              </Select>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
        <ShouldRenderInput propertyGroupedData={moduleData} idEmbarcado={DevicePropertyType.VelocidadeRS232}>
          <Col xl={4} md={12} sm={24}>
            <BaseFormInputItem label="Velocidade serial - RS232 (kbps)" supportText="Escolha apenas uma opção">
              <Select
                disabled={disableAllFields}
                placeholder="Selecione a velocidade"
                value={propertyValue(DevicePropertyType.VelocidadeRS232) || undefined}
                onChange={(value) => {
                  handleInputChange(DevicePropertyType.VelocidadeRS232, value as string);
                }}
                showArrow
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Option className="option-tab" value="115200">
                  115.200
                </Option>
                <Option className="option-tab" value="19200">
                  19.200
                </Option>
                <Option className="option-tab" value="9600">
                  9.600
                </Option>
              </Select>
            </BaseFormInputItem>
          </Col>
        </ShouldRenderInput>
      </Row>
    </>
  );
};

export default IdentificadorMotorista;
