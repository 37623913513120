import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal } from '@app/components/common/Modal/Modal';
import { Spinner } from '@app/components/common/Spinner/Spinner';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setHeaderRegister } from '@app/store/slices/headerRegisterSlice';
import { Col, Row, Space } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { setFooter } from '@app/store/slices/footerSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ModelDeviceModel } from '@app/domain/modelDevice/modelDeviceModel';
import { EquipmentModel } from '@app/domain/equipment/equipmentModel';
import { EquipmentService } from '@app/services/equipmentService';
import { ModelDeviceService } from '@app/services/modelDeviceService';
import { DeviceProfileService } from '@app/services/deviceProfileService';
import {
  DeviceProfileModel,
  DeviceProfilePropertyModel,
  PropertyGroupedData,
  PropertyValue,
} from '@app/domain/deviceProfile/deviceProfileModel';
import { PageContainer } from '@app/components/common/PageContainer/PageContainer';
import { Description } from '@app/components/common/Description/Description';
import { BaseFormInputItem } from '@app/components/common/forms/components/BaseFormInputItem/BaseFormInputItem';
import { Select } from '@app/components/common/selects/Select/Select';
import { Input } from '@app/components/common/inputs/Input/Input';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Radio, RadioGroup } from '@app/components/common/Radio/Radio';
import ModuleComponent from '@app/pages/device-profile/components/ModuleComponent';
import * as S from './styles';
import { Panel } from '@app/components/common/Collapse/Collapse';
import { CAN } from '@app/components/common/ModulesProfileConfig/CAN/CAN';
import { Cerca } from '@app/components/common/ModulesProfileConfig/Cerca/Cerca';
import ICanVehicleService, { CanVehicleService } from '@app/services/canVehicleService';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import IFenceService, { FenceService } from '@app/services/fenceService';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import { FirmwareGroupModel } from '@app/domain/firmwareGroup/firmwareGroupModel';
import { FirmwareGroupService } from '@app/services/firmwareGroupService';
import { CanVehicleParametersCalculatedModel, CanVehicleParametersOnOffModel } from '@app/domain/canVehicle/canVehicle';
import { readUser } from '@app/services/localStorage.service';
import { UserType } from '@app/constants/enums/userType';
import { ClientModel } from '@app/domain/client/clientModel';
import IClientService, { ClientService } from '@app/services/clientService';
import { SendParameter, setParameters } from '@app/store/slices/sendParametersSlice';

const equipmentTypeService = new EquipmentService();
const modelDeviceService = new ModelDeviceService();
const firmwareGroupService = new FirmwareGroupService();
const deviceProfileService = new DeviceProfileService();
const canVehicleService: ICanVehicleService = new CanVehicleService();
const groupFenceService: IFenceService = new FenceService();
const clientService: IClientService = new ClientService();

export const DeviceProfileCreate: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isVehicleCANValid, setIsVehicleCANValid] = useState(true);
  const [isGroupFenceValid, setIsGroupFenceValid] = useState(true);
  const [idManufacturer, setIdManufacturer] = useState<number>();
  const [idVehicle, setIdVehicle] = useState<number>();
  const [idGroupFence, setIdGroupFence] = useState<number>();

  const [modalCancel, setModalCancel] = useState(false);
  const [modalConfirmSave, setModalConfirmSave] = useState(false);
  const getUser = JSON.parse(localStorage.getItem('user') ?? '{}');
  const [deviceProfile, setDeviceProfile] = useState<DeviceProfileModel>({
    ativo: true,
  });
  const [equipmentTypesOptions, setEquipmentTypesOptions] = useState<EquipmentModel[]>([]);
  const [modelOptions, setModelOptions] = useState<ModelDeviceModel[]>([]);
  const [firmwareGroupOptions, setFirmwareGroupOptions] = useState<FirmwareGroupModel[]>([]);
  const [data, setData] = useState<DeviceProfilePropertyModel[]>([]);
  const [properties, setProperties] = useState<PropertyValue[]>([]);
  const [selectedParametersCalculated, setSelectedParametersCalculated] = useState<
    CanVehicleParametersCalculatedModel[]
  >([]);
  const [selectedParametersOnOff, setSelectedParametersOnOff] = useState<CanVehicleParametersOnOffModel[]>([]);
  const [isFactory, setIsFactory] = useState<boolean>(false);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [sendParameters, setSendParameters] = useState<SendParameter[]>([]);

  const fetchEquipmentTypesOptions = useCallback(() => {
    setLoading(true);
    return equipmentTypeService
      .getArray('')
      .then((res: EquipmentModel[]) => {
        setEquipmentTypesOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchModelOptions = useCallback(() => {
    setLoading(true);
    return modelDeviceService
      .getArray('')
      .then((res: ModelDeviceModel[]) => {
        setModelOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchGroupFirmwareOptions = useCallback((modelId: number) => {
    setLoading(true);
    return firmwareGroupService
      .getArray(`obter-todos-por-id-modelo/${modelId}`)
      .then((res) => {
        setFirmwareGroupOptions(res);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchDeviceProfile = useCallback((deviceProfileId: number) => {
    setLoading(true);
    return deviceProfileService
      .get(`/${deviceProfileId}`)
      .then((res) => {
        setDeviceProfile(res);
        setProperties(res.propriedades ?? []);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchComboData = useCallback(async () => {
    const fetchArray = [fetchEquipmentTypesOptions(), fetchModelOptions()];

    if (Number(id)) fetchArray.push(fetchDeviceProfile(Number(id)));

    try {
      await Promise.all(fetchArray);
    } catch (error) {
      notificationController.error({
        message: 'Erro ao buscar dados',
      });
    }
  }, [fetchDeviceProfile, fetchEquipmentTypesOptions, fetchModelOptions, id]);

  const handleCancelRegister = () => {
    setModalCancel(false);
    navigate(`/perfil-configuracao`);
  };

  const handleBackClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setModalCancel(true);
  };

  useEffect(() => {
    // Fetch clients
    if (isFactory) fetchClients();
  }, [isFactory]);

  useEffect(() => {
    const user = readUser();
    if (user?.type === UserType.Factory || user?.type === UserType.FactoryAdmin) setIsFactory(true);

    dispatch(
      setHeaderRegister({
        title: Number(id) ? 'Editar perfil de configuração' : 'Novo perfil de configuração',
        handleBackClick: handleBackClick,
      }),
    );
    fetchComboData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canSave = useMemo(() => {
    return (
      deviceProfile.idEquipamento !== undefined &&
      deviceProfile.idModelo !== undefined &&
      deviceProfile.idGrupoFirmware !== undefined &&
      deviceProfile.nome !== undefined &&
      deviceProfile.nome !== '' &&
      isGroupFenceValid &&
      isVehicleCANValid
    );
  }, [deviceProfile, isGroupFenceValid, isVehicleCANValid]);

  useEffect(() => {
    dispatch(
      setFooter({
        confirmButtonDisabled: !canSave,
        confirmButtonText: 'Salvar',
        handleCancelButtonClick: () => setModalCancel(true),
        handleConfirmButtonClick: () => setModalConfirmSave(true),
        cancelButtonText: 'Cancelar',
      }),
    );
  }, [canSave, dispatch]);

  const fetchClients = async () => {
    try {
      const clientsResponse = await clientService.getArray('');
      setClients(clientsResponse);
    } catch (error) {
      notificationController.error({ message: 'Erro ao buscar a lista de clientes' });
    }
  };

  const fetchPropertiesByModel = useCallback((idModelo: number, idGrupoFirmware: number) => {
    setLoading(true);
    return firmwareGroupService
      .getArray(`/${idGrupoFirmware}/${idModelo}/propriedades`)
      .then((res: DeviceProfilePropertyModel[]) => {
        setData(res);
      })
      .catch((error) => {
        notificationController.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (deviceProfile.idModelo) fetchGroupFirmwareOptions(deviceProfile.idModelo);
    else setData([]);
  }, [deviceProfile.idModelo, fetchGroupFirmwareOptions]);

  useEffect(() => {
    if (deviceProfile.idGrupoFirmware && deviceProfile.idModelo)
      fetchPropertiesByModel(deviceProfile.idModelo, deviceProfile.idGrupoFirmware);
    else setData([]);
  }, [deviceProfile.idGrupoFirmware, deviceProfile.idModelo, fetchPropertiesByModel]);

  const groupedData = data.reduce((acc: PropertyGroupedData, item: DeviceProfilePropertyModel) => {
    const key = item.modulo || 'unknown';
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as PropertyGroupedData);

  const generateVehicleCanProperties = async (): Promise<PropertyValue[]> => {
    const canProperties = groupedData['CAN'];
    if (!idVehicle)
      return [
        {
          idPropriedade: canProperties.find((p) => p.idEmbarcado == DevicePropertyType.RedeCanHabilitada)
            ?.idPropriedade,
          idEmbarcado: DevicePropertyType.RedeCanHabilitada,
          valorPropriedade: '0',
          idPerfilDispositivo: deviceProfile.id,
        },
      ] as unknown as PropertyValue[];

    const vehicle = await canVehicleService.get(`${idVehicle}`);

    const vehicleProperties = [
      {
        idPropriedade: canProperties.find((p) => p.idEmbarcado == DevicePropertyType.RedeCanHabilitada)?.idPropriedade,
        idEmbarcado: DevicePropertyType.RedeCanHabilitada,
        valorPropriedade: '1',
      },
      {
        idPropriedade: canProperties.find((p) => p.idEmbarcado == 86)?.idPropriedade,
        idEmbarcado: 86,
        valorPropriedade: vehicle.tipoInformacao,
      },
      {
        idPropriedade: canProperties.find((p) => p.idEmbarcado == 87)?.idPropriedade,
        idEmbarcado: 87,
        valorPropriedade: vehicle.tipoVeiculo,
      },
      {
        idPropriedade: canProperties.find((p) => p.idEmbarcado == DevicePropertyType.BitrateCan)?.idPropriedade,
        idEmbarcado: DevicePropertyType.BitrateCan,
        valorPropriedade: vehicle.velocidadeCAN?.toString(),
      },
      {
        idPropriedade: canProperties.find((p) => p.idEmbarcado == DevicePropertyType.ModeloVeiculoCan)?.idPropriedade,
        idEmbarcado: DevicePropertyType.ModeloVeiculoCan,
        valorPropriedade: `${vehicle.nome}`,
      },
      {
        idPropriedade: canProperties.find((p) => p.idEmbarcado == DevicePropertyType.IdentifierCanSalvos)
          ?.idPropriedade,
        idEmbarcado: DevicePropertyType.IdentifierCanSalvos,
        valorPropriedade: `${
          (vehicle.parametroCalculado.length ?? 0) + (vehicle.parametroLigadoDesligado.length ?? 0)
        }`,
      },
    ] as PropertyValue[];

    const idPropertyAddCan = canProperties.find((c) => c.idEmbarcado == DevicePropertyType.AdicionarIdentifierCan)
      ?.idPropriedade;

    selectedParametersCalculated.map((p) => {
      vehicleProperties.push({
        idPropriedade: idPropertyAddCan,
        idEmbarcado: DevicePropertyType.AdicionarIdentifierCan,
        valorPropriedade: `${p.idIdentifierCAN},${p.parametroId},0,${p.resolucao},${p.offset},${p.unidadeMedida ?? 0},${
          p.bitInicial
        },${p.tamanhoBits}`,
      } as PropertyValue);
    });

    selectedParametersOnOff.map((p) => {
      vehicleProperties.push({
        idPropriedade: idPropertyAddCan,
        idEmbarcado: DevicePropertyType.AdicionarIdentifierCan,
        valorPropriedade: `${p.idIdentifierCAN},${p.parametroId},1,1,0,${p.ligado},${p.bitInicial},${p.tamanhoBits}`,
      } as PropertyValue);
    });

    return vehicleProperties.map((p) => ({
      ...p,
      valorPropriedade: p.valorPropriedade.toString().replace(/[^a-zA-Z0-9;.,-]/g, ''),
    }));
  };

  const generateGroupFenceProperties = async (): Promise<PropertyValue[]> => {
    if (!idGroupFence) return [];

    const group = await groupFenceService.get(`/grupos/${idGroupFence}`);
    const fenceProperties = groupedData['Cercas'];

    if (!group) return [];

    const fenceGroupProperties = [
      {
        idPropriedade: fenceProperties.find((p) => p.idEmbarcado == DevicePropertyType.IdGrupoCercas)?.idPropriedade,
        idEmbarcado: DevicePropertyType.IdGrupoCercas,
        valorPropriedade: idGroupFence.toString(),
      } as PropertyValue,
    ].concat(
      group.cercas?.map((cerca) => ({
        idPropriedade: fenceProperties.find((p) => p.idEmbarcado == DevicePropertyType.AdicionarCerca)?.idPropriedade,
        idEmbarcado: DevicePropertyType.AdicionarCerca,
        valorPropriedade: cerca.propriedade?.valorPropriedade,
      })) as PropertyValue[],
    );

    return fenceGroupProperties;
  };

  const saveNewProfile = useCallback(async () => {
    try {
      setLoading(true);

      let propertiesToSend = properties;

      if (idGroupFence) propertiesToSend = propertiesToSend.concat(await generateGroupFenceProperties());
      if (idVehicle) propertiesToSend = propertiesToSend.concat(await generateVehicleCanProperties());

      const dataToSave = {
        ...deviceProfile,
        idCliente: isFactory ? deviceProfile.idCliente : getUser.idCliente ?? 1,
        idVeiculoCan: idVehicle,
        idFabricante: idManufacturer,
        idGrupoCerca: idGroupFence,
        parametrosCalculadoSelecionados: selectedParametersCalculated,
        parametrosLigadoDesligadoSelecionado: selectedParametersOnOff,
        propriedades: propertiesToSend,
      } as DeviceProfileModel;

      const response = await deviceProfileService.post('', dataToSave);

      if (response.id) {
        await deviceProfileService.insertSelectedCanParameters(
          idVehicle ?? 0,
          selectedParametersCalculated,
          selectedParametersOnOff,
          response.id ?? 0,
        );

        notificationController.success({
          message: 'Sucesso',
          description: 'Perfil de configuração cadastrado com sucesso',
        });

        setIdVehicle(undefined);
        setIdGroupFence(undefined);
        navigate(`/perfil-configuracao`);
      } else {
        notificationController.error({ message: 'Erro ao criar o perfil.' });
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      notificationController.error(error);
    } finally {
      setLoading(false);
      setModalConfirmSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceProfile, idGroupFence, idVehicle, idManufacturer, selectedParametersCalculated, selectedParametersOnOff]);

  const fetchSendProfileProperties = useCallback(
    async (idVersaoFirmware: number) => {
      setLoading(true);
      return firmwareGroupService
        .getArray(`obter-propriedades-perfil-envio/${idVersaoFirmware}`)
        .then((res) => {
          dispatch(setParameters(res as unknown as SendParameter[]));
          setSendParameters(res as unknown as SendParameter[]);
        })
        .catch((error) => {
          notificationController.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch],
  );

  useEffect(() => {
    if (deviceProfile.idGrupoFirmware) fetchSendProfileProperties(deviceProfile.idGrupoFirmware);
  }, [deviceProfile.idGrupoFirmware, fetchSendProfileProperties]);

  // Método para buscar o grupo firmware, apenas para uma alteração de protocolo de comunicação solicitada em 06/12/2024
  const handleFindFirmwareGroup = () => {
    return firmwareGroupOptions.find((f) => f.id === deviceProfile.idGrupoFirmware)?.nome;
  };

  return (
    <>
      <Modal
        title="Cancelar agendamento"
        open={modalCancel}
        onOk={() => handleCancelRegister()}
        onCancel={() => setModalCancel(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            <InfoCircleOutlined size={20} style={{ color: '#FAAD14', marginRight: '1rem' }} />
            <span>
              Deseja realmente <strong> cancelar {Number(id) ? 'a edição' : 'o cadastro'} ?</strong>
            </span>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Confirmar cadastro"
        open={modalConfirmSave}
        onOk={() => {
          saveNewProfile();
        }}
        onCancel={() => setModalConfirmSave(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <Row align="middle">
          <Col>
            {/*<InfoCircleOutlined size={20} style={{ color: '#C41B24' }} />*/}
            <span>
              Deseja realmente <strong>confirmar {Number(id) ? 'a edição' : 'o cadastro'} ?</strong>
            </span>
          </Col>
        </Row>
      </Modal>

      <Spinner spinning={loading}></Spinner>
      <PageContainer>
        <Description title="Informações do perfil" subtitle="Preencha os campos abaixo para cadastrar um novo perfil" />
        <BaseForm layout="vertical" style={{ width: '100%' }}>
          <Row gutter={18}>
            {isFactory && (
              <Col xl={6} md={12} sm={24}>
                <BaseFormInputItem label="Cliente">
                  <Select
                    showArrow
                    showSearch
                    placeholder="Selecione o cliente"
                    value={deviceProfile.idCliente}
                    onChange={(value) =>
                      setDeviceProfile((prevState) => ({
                        ...prevState,
                        idCliente: value as number,
                      }))
                    }
                    options={clients.map((c) => ({
                      value: c.id,
                      label: c.razaoSocial,
                    }))}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </BaseFormInputItem>
              </Col>
            )}
            <Col xl={6} md={12} sm={24}>
              <BaseFormInputItem label="Equipamento">
                <Select
                  showArrow
                  showSearch
                  placeholder="Selecione o equipamento"
                  value={deviceProfile.idEquipamento}
                  disabled={isFactory ? deviceProfile.idCliente === undefined : !!Number(id)}
                  onChange={(value) =>
                    setDeviceProfile((prevState) => ({
                      ...prevState,
                      idEquipamento: value as number,
                      idModelo: undefined,
                    }))
                  }
                  options={equipmentTypesOptions.map((c) => ({
                    value: c.id,
                    label: c.nome,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </BaseFormInputItem>
            </Col>
            <Col xl={6} md={12} sm={24}>
              <BaseFormInputItem label="Modelo">
                <Select
                  showArrow
                  showSearch
                  placeholder="Selecione o modelo"
                  value={deviceProfile.idModelo}
                  disabled={!deviceProfile.idEquipamento || !!Number(id)}
                  style={{ width: '100%' }}
                  onChange={(value) =>
                    setDeviceProfile((prevState) => ({
                      ...prevState,
                      idModelo: value as number,
                      idGrupoFirmware: undefined,
                      propriedades: [],
                    }))
                  }
                  options={modelOptions
                    .filter((model) => model.idEquipamento === deviceProfile.idEquipamento)
                    .map((model) => ({
                      value: model.id,
                      label: model.nome,
                    }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </BaseFormInputItem>
            </Col>
            <Col xl={6} md={12} sm={24}>
              <BaseFormInputItem label="Grupo de firmware">
                <Select
                  showArrow
                  showSearch
                  placeholder="Selecione o grupo de firmware"
                  value={deviceProfile.idGrupoFirmware}
                  disabled={!deviceProfile.idModelo || !!Number(id)}
                  style={{ width: '100%' }}
                  onChange={(value) =>
                    setDeviceProfile((prevState) => ({
                      ...prevState,
                      idGrupoFirmware: value as number,
                    }))
                  }
                  options={firmwareGroupOptions.map((c) => ({
                    value: c.id,
                    label: c.nome,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </BaseFormInputItem>
            </Col>
            <Col xl={6} md={12} sm={24}>
              <BaseFormInputItem label="Nome do perfil">
                <Input
                  placeholder="Informe o nome do perfil"
                  value={deviceProfile.nome}
                  disabled={isFactory ? deviceProfile.idCliente === undefined : false}
                  onChange={(event) => {
                    setDeviceProfile((prevState) => ({
                      ...prevState,
                      nome: event.target.value,
                    }));
                  }}
                />
              </BaseFormInputItem>
            </Col>
          </Row>
          <Row>
            <BaseFormInputItem label="Status do perfil">
              <RadioGroup
                value={deviceProfile.ativo}
                onChange={(event) => {
                  setDeviceProfile((prevState) => ({
                    ...prevState,
                    ativo: event.target.value,
                  }));
                }}
              >
                <Space size={8} direction="vertical">
                  <Radio value={true} checked={deviceProfile.ativo}>
                    Ativo
                  </Radio>
                  <Radio value={false} checked={!deviceProfile.ativo}>
                    Inativo
                  </Radio>
                </Space>
              </RadioGroup>
            </BaseFormInputItem>
          </Row>
          {Object.keys(groupedData)
            .filter((name) => name != 'CAN' && name != 'Cercas')
            .map((moduleName) => (
              <ModuleComponent
                key={moduleName}
                properties={properties}
                setProperties={setProperties}
                moduleData={groupedData[moduleName]}
                moduleName={moduleName}
                sendParameters={sendParameters}
                firmwareGroup={handleFindFirmwareGroup()}
              />
            ))}
          {Object.keys(groupedData).find((name) => name == 'CAN') && (
            <S.CollapseContainer>
              <S.Collapse bordered={false} defaultActiveKey="can">
                <Panel header="Veículos CAN" key="can">
                  <CAN
                    setIsValid={setIsVehicleCANValid}
                    idVehicle={idVehicle}
                    setIdVehicle={setIdVehicle}
                    idManufacturer={idManufacturer}
                    setIdManufacturer={setIdManufacturer}
                    selectedParametersCalculated={selectedParametersCalculated}
                    selectedParametersOnOff={selectedParametersOnOff}
                    setSelectedParametersCalculated={setSelectedParametersCalculated}
                    setSelectedParametersOnOff={setSelectedParametersOnOff}
                  />
                </Panel>
                <div style={{ paddingLeft: '16px' }}>
                  <DividerLine />
                </div>
              </S.Collapse>
            </S.CollapseContainer>
          )}
          {Object.keys(groupedData).find((name) => name == 'Cercas') && (
            <S.CollapseContainer>
              <S.Collapse bordered={false} defaultActiveKey="cercas">
                <Panel header="Cercas" key="cercas">
                  <Cerca
                    setIsValid={setIsGroupFenceValid}
                    idGroup={idGroupFence}
                    setIdGroup={setIdGroupFence}
                    idModel={deviceProfile.idModelo!}
                  />
                </Panel>
                <S.Divisor />
              </S.Collapse>
            </S.CollapseContainer>
          )}
        </BaseForm>
      </PageContainer>
    </>
  );
};
