import { DeviceMethods } from '@app/constants/enums/device/device-methods';
import { DevicePropertyType } from '@app/constants/enums/device/device-property-type';
import { notificationController } from '@app/controllers/notificationController';

export default class DeviceSerialPort {
  private static instance: DeviceSerialPort;
  public connected: boolean;
  private dataReceived: string;
  private port: SerialPort | undefined;
  private reader: ReadableStreamDefaultReader | undefined;
  private writer: WritableStreamDefaultWriter | undefined;

  private constructor() {
    this.connected = false;
    this.dataReceived = '';
  }

  public static getInstance(): DeviceSerialPort {
    if (!DeviceSerialPort.instance) {
      DeviceSerialPort.instance = new DeviceSerialPort();
    }
    return DeviceSerialPort.instance;
  }

  async connect(): Promise<boolean> {
    try {
      this.port = await navigator.serial.requestPort({
        filters: [
          { usbVendorId: 12346, usbProductId: 0x4002 }, // J-R12
          { usbVendorId: 7694, usbProductId: 36881 }, // J-R11
          { usbVendorId: 0x303a, usbProductId: 0x4002 }, // J-M15
        ],
      });
      this.connected = true;
      return true;
    } catch (error) {
      console.error(error);
      notificationController.error({
        message: `Erro ao conectar com o dispositivo`,
      });
      return false;
    }
  }

  private async open(): Promise<void> {
    try {
      if (!this.connected) {
        throw Error('Dispositivo desconectado');
      }

      if (this.port && this.port.readable && this.port.writable) {
        console.warn('Port is already open.');
        return;
      }

      await this.port?.open({ baudRate: 115200 });
      this.reader = await this.port?.readable.getReader();
      this.writer = await this.port?.writable.getWriter();
    } catch (error) {
      console.error(error);
      notificationController.error({
        message: `Erro ao abrir conexão com o dispositivo.`,
        description: 'Sincronize o dispositivo novamente',
      });
      throw error;
    }
  }

  public async close(): Promise<void> {
    try {
      if (this.port && this.port.readable && this.port.writable) {
        await this.port?.close();
        this.reader = undefined;
        this.writer = undefined;
      }
    } catch (error) {
      console.error(error);
      notificationController.error({
        message: `Erro ao fechar conexão com o dispositivo.`,
      });
      throw error;
    }
  }

  private async write(data: string): Promise<void> {
    try {
      if (!this.port?.writable) {
        throw new Error('Writable stream is not available');
      }

      // Tentar obter um escritor apenas se não houver um já ativo.
      if (!this.writer || this.writer.desiredSize === null) {
        this.writer = this.port.writable.getWriter();
      }

      console.log(data);
      console.log(new TextEncoder().encode(data));
      await this.writer.write(new TextEncoder().encode(data));
    } catch (error) {
      console.error('Erro ao enviar dados pela porta serial:', error);
      notificationController.error({
        message: `Erro ao enviar dados pela porta serial: ${error}`,
      });
    } finally {
      // Liberação do lock deve acontecer aqui para garantir que ocorre independentemente de erros.
      if (this.writer) {
        this.writer.releaseLock();
        this.writer = undefined; // Limpar a referência ao writer após a liberação.
      }
    }
  }

  private async read(callback?: (readedValue: string) => void): Promise<void> {
    try {
      if (!this.reader || !this.port?.readable) {
        this.reader = this.port?.readable.getReader();
        if (!this.reader) throw Error('Erro ao inicializar a comunicação com o dispositivo');
      }

      let keepReading = true;
      while (keepReading) {
        const { value, done } = await this.reader.read();
        if (done) {
          keepReading = false;
          break;
        }

        const currentDataReceived = new TextDecoder().decode(value);
        this.dataReceived += currentDataReceived;

        if (callback) {
          if (!currentDataReceived.includes('OK') && this.dataReceived.replace(/\x00/g, '').endsWith('\r\n')) {
            const lastData = this.dataReceived
              .replace(/\x00/g, '')
              .split('\r\n')
              .filter((x) => x !== '')
              .pop();
            if (lastData) callback(lastData);
            continue;
          }
          continue;
        }

        if (this.dataReceived.includes('\r\n')) keepReading = false;
      }
    } catch (error) {
      console.error('Erro na leitura:', error);
      throw error;
    } finally {
      if (this.reader) {
        if (this.port?.getInfo().usbVendorId !== 7694) await this.reader?.cancel();
        this.reader?.releaseLock();
        this.reader = undefined;
      }
    }
  }

  private async send(command: string): Promise<string> {
    try {
      this.dataReceived = '';

      if (!this.connected) {
        throw Error('Dispositivo desconectado');
      }

      await this.open();
      await this.write(command);
      await this.read();
      await this.port?.readable?.cancel();
      await this.port?.writable?.close();
      await this.port?.close();
      return this.dataReceived.replace('\r\n', '').replace('\0', '');
    } catch (error) {
      throw error;
    }
  }

  async get(property: DevicePropertyType): Promise<string> {
    return await this.send(`${DeviceMethods.GetInfo};${property};`);
  }

  // sending every 5 properties at a time
  async setMultiple(
    method: DeviceMethods,
    property: DevicePropertyType,
    data: string[],
    batchSize: number = 5,
  ): Promise<boolean> {
    try {
      for (let i = 0; i < (data?.length ?? 0); i += batchSize) {
        const commandParts = [];
        for (let j = i; j < Math.min(data.length, i + batchSize); j++) {
          commandParts.push(`${method};${property};${data[j]};`);
        }
        const command = commandParts.join('|');
        const response = await this.send(command);

        if (response.includes('Invalid param!')) {
          notificationController.info({
            message: 'Info!',
            description: `Houve um problema ao atualizar a propriedade: ${DevicePropertyType[property]}`,
            duration: 20,
          });
          // 12/06/24 - solicitação do embarcado
          // throw Error('Não foi possível finalizar a atualização do dispositivo, existem parâmetros inválidos.');
        }

        if (response.includes('Invalid command!')) {
          notificationController.info({
            message: 'Info:',
            description: `Erro ao atualizar a propriedade: ${DevicePropertyType[property]}`,
            duration: 20,
          });
        }
      }

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async set(method: DeviceMethods, property: DevicePropertyType, data?: string): Promise<boolean> {
    try {
      const command = `${method};${property};${data ? `${data};` : ''}`;
      const response = await this.send(command);

      if (response.includes('Invalid param!')) {
        notificationController.info({
          message: 'Info!',
          description: `Houve um problema ao atualizar a propriedade: ${DevicePropertyType[property]}`,
          duration: 20,
        });
        // 12/06/24 - solicitação do embarcado
        // throw Error('Não foi possível finalizar a atualização do dispositivo, existem parâmetros inválidos.');
      }

      if (response.includes('Invalid command!')) {
        notificationController.info({
          message: 'Info:',
          description: `Erro ao atualizar a propriedade: ${DevicePropertyType[property]}`,
          duration: 20,
        });
      }

      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async confirm(property: DevicePropertyType, data: string): Promise<boolean> {
    const response = await this.send(`${DeviceMethods.Enabled};${property};${data};`);

    if (response.includes('Invalid param!')) {
      notificationController.info({
        message: 'Info!',
        description: `Houve um problema ao atualizar a propriedade: ${DevicePropertyType[property]}`,
        duration: 20,
      });
      // 12/06/24 - solicitação do embarcado
      // throw Error('Não foi possível finalizar a atualização do dispositivo, existem parâmetros inválidos.');
    }

    if (response.includes('Invalid command!')) {
      notificationController.info({
        message: 'Info:',
        description: `Erro ao atualizar a propriedade: ${DevicePropertyType[property]}`,
        duration: 20,
      });
    }

    return true;
  }

  public async startContinuousRead(callback: (tagData: string) => void): Promise<void> {
    try {
      if (!this.connected) {
        throw new Error('Dispositivo desconectado');
      }

      // unlock the reader if it is locked
      if (this.reader) {
        await this.reader.cancel();
        this.reader.releaseLock();
      }

      await this.set(DeviceMethods.SetInfo, DevicePropertyType.ModoLeituraTags, '0');
      await this.set(DeviceMethods.SetInfo, DevicePropertyType.ModoLeituraTags, '1');

      await this.open();
      await this.read(callback);
    } catch (error) {
      console.error('Error in continuous read:', error);
    }
  }

  public async stopContinuousRead(): Promise<void> {
    try {
      await this.set(DeviceMethods.SetInfo, DevicePropertyType.ModoLeituraTags, '0');
    } catch (error) {
      console.error('Error stopping continuous read:', error);
    }
  }
}
