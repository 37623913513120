import React, { FC } from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;
import * as S from '../styles';
import { DeviceProfilePropertyModel, PropertyValue } from '@app/domain/deviceProfile/deviceProfileModel';
import Rede from '@app/components/common/ModulesProfileConfig/Rede/Rede';
import PerfilEnvio from '@app/components/common/ModulesProfileConfig/PerfilEnvio/PerfilEnvio';
import Eventos from '@app/components/common/ModulesProfileConfig/Eventos/Eventos';
import Entrada from '@app/components/common/ModulesProfileConfig/Entrada/Entrada';
import { DividerLine } from '@app/components/common/divider/DividerLine.styles';
import IdentificadorMotorista from '@app/components/common/ModulesProfileConfig/IdentificadorMotorista/IdentificadorMotorista';
import { SendParameter } from '@app/store/slices/sendParametersSlice';

interface ModuleComponentProps {
  properties: PropertyValue[];
  setProperties: React.Dispatch<React.SetStateAction<PropertyValue[]>>;
  moduleData: DeviceProfilePropertyModel[];
  moduleName: string;
  disableAllFields?: boolean;
  hasBorderBottom?: boolean;
  sendParameters: SendParameter[];
  firmwareGroup?: string;
}
// Componente ModuleComponent
const ModuleComponent: FC<ModuleComponentProps> = ({
  properties,
  setProperties,
  moduleData,
  moduleName,
  disableAllFields = false,
  hasBorderBottom = true,
  sendParameters,
  firmwareGroup,
}) => {
  const renderModuleContent = (name: string) => {
    switch (name) {
      case 'Rede':
        return (
          <Rede
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            disableAllFields={disableAllFields}
          />
        );
      case 'Entrada':
        return (
          <Entrada
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            disableAllFields={disableAllFields}
          />
        );
      case 'PerfilEnvio':
        return (
          <PerfilEnvio
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            disableAllFields={disableAllFields}
            parameters={sendParameters}
          />
        );
      case 'Evento':
        return (
          <Eventos
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            disableAllFields={disableAllFields}
          />
        );
      case 'IdentificadorMotorista':
        return (
          <IdentificadorMotorista
            properties={properties}
            setProperties={setProperties}
            moduleData={moduleData}
            disableAllFields={disableAllFields}
            firmwareGroup={firmwareGroup}
          />
        );
      default:
        return <></>;
    }
  };

  const renderModuleTitle = (name: string) => {
    switch (name) {
      // case 'Rede':
      //   return 'Rede';
      // case 'Entrada':
      //   return 'Entrada';
      case 'PerfilEnvio':
        return 'Perfil de envio';
      // case 'Eventos':
      //   return 'Eventos';
      case 'CAN':
        return 'Veículos CAN';
      case 'IdentificadorMotorista':
        return 'Configurações';
      // case 'Cercas':
      //   return 'Cercas';
      default:
        return name;
    }
  };

  return (
    <S.CollapseContainer>
      <S.Collapse bordered={false} defaultActiveKey={moduleName} style={{ paddingLeft: '-16px' }}>
        <Panel header={renderModuleTitle(moduleName)} key={moduleName} style={{ paddingLeft: '-16px' }}>
          {renderModuleContent(moduleName)}
        </Panel>
        {hasBorderBottom && (
          <div style={{ paddingLeft: '16px' }}>
            <DividerLine />
          </div>
        )}
      </S.Collapse>
    </S.CollapseContainer>
  );
};

export default ModuleComponent;
